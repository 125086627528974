@media (min-width: 2160px) {
  .main-container {
    max-width: 2150px;
  }
}

@media (max-width: 1366px) {
  nav {
    height: 110px;
  }
  #hero {
    height: auto;
  }
}
@media (max-width: 768px) {
  //Typos
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2.25rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.5rem;
  }

  .main-heading {
    margin-top: 3rem;
    &::after {
      height: 14px;
      width: 75px;
      margin: 1rem auto;
    }
  }

  .social-links {
    transform: scale(0.7);
  }

  //Buttons
  .btn {
    &.large {
      font-size: 1.25rem;
      padding: 0.75rem 1.75rem;
    }
  }

  //Other

  .main-container {
    padding: 0 1rem;
  }

  nav {
    &.fixed {
      height: 110px !important;
    }
    .main-container {
      display: flex;
      flex-direction: column;
      .logo {
        margin-top: 1rem;
      }
    }
    ul {
      gap: 1.5rem;
      li {
        a {
          font-size: 1rem;
        }
      }
    }
  }

  #hero {
    .main-container {
      flex-direction: column-reverse;
      justify-content: center;
    }
    .content {
      text-align: center;
    }
    .name::after {
      right: 0;
      top: 1.8em;
      margin: auto;
    }
    .hero-image {
      margin: 0 auto;
      margin-bottom: 32px;
    }
  }

  //cards

  .card.feature-portfolio {
    flex-direction: column-reverse;
    &:nth-child(even) {
      flex-direction: column-reverse;
    }
    .content {
      padding: 2.25rem 1.5rem;
      .btn {
        margin-top: 1rem;
      }
    }

    .portfolio-image {
      height: 250px;
    }
  }

  #featured-portfolio {
    .cards-wrapper {
      gap: 2.5rem;
    }
  }
  .ph.feature-portfolio {
    .h3 {
      height: 1.5rem;
    }
    .p {
      height: 0.75rem;
      margin-top: 1rem !important;
    }
    .btn {
      margin-top: 1.5rem !important;
    }
  }
  #featured-portfolio {
    padding-bottom: 2rem;
    &::after {
      clip-path: ellipse(100% 25% at 50% 100%);
    }
    .footer-container {
      margin-top: 4rem;
    }
  }

  .single-portfolio {
    #single-hero {
      &.main-container {
        flex-direction: column-reverse;
        justify-content: flex-start;
        min-height: 100%;
        gap: 2rem;
      }
      .content {
        width: 100%;
        .type {
          font-size: 1.25rem;
        }
      }
      .hero-image {
        margin: 0 !important;
        img {
          margin-top: 2rem;
        }
      }
    }
    .color-pallet {
      .color-drop {
        width: 140px;
        height: 130px;
      }
    }

    .structures {
      img {
        max-width: 100%;
      }
    }

    .screenshots {
      gap: 3rem;
    }
  }

  #my-story {
    .main-container {
      flex-direction: column-reverse;
      .content {
        width: 100%;
        p {
          font-size: 1.25rem;
        }
      }
      .hero-image {
        margin-top: 0;
      }
    }
  }
  //query ends here
}
