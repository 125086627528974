h1,
h2,
h3,
h6 {
  margin: 0;
}

h1 {
  font-size: 4.875rem;
}
h2 {
  font-size: 3rem;
}
h3 {
  font-size: 2.125rem;
}
h4 {
  font-size: 1.75rem;
  font-weight: normal;
}
h5 {
  font-size: 1.25rem;
}
p {
  font-size: 1rem;
  line-height: 2;
}
