.card {
  background: $white;
  border-radius: 0.5rem;
  box-shadow: 4px 4px 24px rgba(#000, 0.05);

  &.feature-portfolio {
    display: flex;
    overflow: hidden;

    &:nth-child(even) {
      flex-direction: row-reverse;
    }

    .content {
      padding: 7.25rem 4.5rem;
      flex: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .btn {
        display: block;
        width: max-content;
        margin-top: 4rem;
      }
    }
    .portfolio-image {
      background-color: #eee;
      height: auto;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      overflow: hidden;
      position: relative;
      transition: all ease-in-out 800ms;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: inherit;
        transition: inherit;
      }
      &:hover::before {
        transform: scale(1.2);
      }
    }
  }
}
