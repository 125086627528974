*,
*:before,
*:after {
  box-sizing: border-box;
}
:root {
  --backgroundColor: #fff;
  --themeColor: $secondary;
  --blur: 10px;
  --textColor: $text-default;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: $default-font;
  color: $text-default;
}

.main-container {
  max-width: 1432px;
  margin: 0 auto;
  padding: 0 2rem;
}

.card-container {
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 2rem;
}

nav {
  height: 134px;
  display: grid;
  align-items: center;
  background-color: rgba($white, 0.9);
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 200ms;
  top: -225px;
  &.fixed {
    top: 0;
    background-color: rgba(#fff, 0.9) !important;
    position: fixed;
    width: 100%;
    backdrop-filter: blur(var(--blur));
    -webkit-backdrop-filter: blur(var(--blur));
    z-index: 2;
    height: 85px;
    box-shadow: 0 8px 32px 0 rgba(163, 163, 163, 0.2);

    ul li a::before {
      backdrop-filter: blur(var(--blur));
      -webkit-backdrop-filter: blur(var(--blur));
    }
  }
  .main-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ul {
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 3.5rem;
    li {
      list-style: none;
      a {
        text-decoration: none;
        color: var(--textColor);
        font-size: 1.275rem;
        position: relative;

        $thickness: 115%; // increase to make the line thicker
        $offset: 2px;

        &:before,
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          opacity: 0;
          width: 34px;
        }
        &:before {
          height: 8px;
          top: $thickness;
          background: linear-gradient(-135deg, var(--themeColor) 5px, transparent 0) 0 5px,
            linear-gradient(135deg, var(--themeColor) 5px, transparent 0) 0 5px;
          background-position: top left;
          background-repeat: repeat-x;
          background-size: 10px 10px;
        }
        &:after {
          height: 10px;
          top: 100%;
          background: linear-gradient(-135deg, var(--backgroundColor) 5px, transparent 0) 0 5px,
            linear-gradient(135deg, var(--backgroundColor) 5px, transparent 0) 0 5px;
          background-position: top left;
          background-repeat: repeat-x;
          background-size: 10px 10px;
        }

        &.active::after,
        &.active::before {
          left: 0;
          opacity: 1;
        }
        &:hover {
          &::after,
          &::before {
            left: 0;
            opacity: 1;
            animation: smooth 3s infinite;
          }
        }
      }
    }
  }
}

#hero {
  height: calc(100vh - 134px);
  display: grid;
  align-items: center;
  // padding-top: 134px;
  .main-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .hero-image {
    margin-right: -32px;
    img {
      max-width: 100%;
      height: auto;
      transform: inherit;
      transition: all ease 400ms;
      &:hover {
        transform: scale(1.01) translateX(-0.5rem);
      }
    }
  }
  .arrow-down {
    margin: 0 auto;
    margin-top: 48px;
    margin-bottom: 72px;
    display: block;
    cursor: pointer;
    animation: scroll 0.7s infinite alternate;
  }
  h4 {
    color: $text-secondary;
    margin-top: 0;
  }
  .name {
    color: $text-default;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      height: 2px;
      width: 280px;
      background: $primary;
      left: 0;
      top: 3.3rem;
    }
  }
  .experience {
    margin-top: 0.5rem;
  }

  .btn-group {
    display: flex;
    gap: 1rem;
  }
}

#featured-portfolio {
  background: #f3f3f3;
  // height: 100vh;
  padding: 8rem 0;
  padding-bottom: 4rem;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    background: $primary;
    width: 100%;
    height: 100%;
    clip-path: ellipse(77% 25% at 50% 100%);
    z-index: 0;
  }

  .cards-wrapper {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    z-index: 1;
    position: relative;
  }

  .footer-container {
    margin-top: 6rem;
    text-align: center;
    color: $white;
    z-index: 1;
    position: relative;

    h3 {
      font-weight: normal;
    }

    .btn {
      margin: 2rem auto;
      margin-bottom: 4rem;
      max-width: max-content;
    }
  }
}
.social-links {
  margin: 0 auto;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  li {
    list-style: none;
    a {
      display: block;
      transition: all ease 400ms;
      &:hover {
        transform: translateY(-10px);
      }
    }
  }
}
#portfolio {
  .category-list {
    margin: 3rem auto;
    padding: 0;
    display: block;
    text-align: center;
    // justify-content: center;
    gap: 1rem;
    li {
      display: inline;
      list-style: none;
      position: relative;
      &:not(:first-child)::before {
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $text-secondary;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
      }
      .btn {
        font-size: 1.2rem;
        text-transform: capitalize;
      }
    }
  }

  .portfolio-grid {
    display: flex;
    grid-gap: 1rem;
    flex-wrap: wrap;
    // grid-template-columns: auto auto auto auto;
    grid-template-columns: repeat(auto-fit, minmax(305px, 1fr));
    align-items: center;
    justify-content: center;
    a {
      text-decoration: none;
    }
    .portfolio-item {
      grid-template-columns: repeat(4, 1fr);
      /* this is the same as grid-template-rows: 300px 300px; */
      grid-template-rows: repeat(2, 330px);
      border-radius: 0.5rem;
      width: 330px;
      height: 230px;
      background: #eee;
      background-position: center;
      background-size: cover;
      overflow: hidden;
      position: relative;
      margin: 0 auto;
      transition: transform ease-in 700ms;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: inherit;
        transition: inherit;
      }
      &:hover::before {
        transform: scale(1.1);
      }
      .portfolio-overlay {
        opacity: 0;
        width: 100%;
        height: 100%;
        position: relative;
        padding: 1.5rem 2rem;
        background: rgba(black, 0.6);
        display: flex;
        align-items: flex-end;
        color: $white;
        transition: all ease-in 400ms;
        border-radius: 0.5rem;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.single-portfolio {
  #single-hero {
    min-height: calc(100vh - 134px);
    display: grid;
    place-content: center;
    // overflow: hidden;
    &.main-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .content {
      width: 40%;
      .type {
        font-size: 2rem;
        margin: 1.25rem 0;
        color: $text-secondary;
        text-transform: capitalize;
      }
      h4 {
        margin-bottom: 1rem;
        font-size: 1.5rem;
      }
      .btn {
        margin-top: 2rem;
        display: flex;
        max-width: max-content;
      }
    }

    .hero-image {
      // margin-right: -3rem;
      // margin-top: -2rem;
      img {
        max-width: 100%;
      }
    }
  }

  h3 {
    margin: 3rem 0;
  }
  section {
    padding-bottom: 2rem;
  }
  #screenshots {
    padding-bottom: 0;
    .screenshots {
      display: flex;
      gap: 4rem;
      flex-direction: column;
      // padding-bottom: 2rem;
    }

    img {
      max-width: 100%;
      border-radius: 0.75rem;
      border: 3px solid rgba(#fff, 0.15);
      box-shadow: 0 20px 60px rgba(black, 0.15);
    }
  }
  .structures {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    img {
      max-width: 650px;
    }
  }

  #typography {
    img {
      margin-top: 2rem;
    }
  }

  #feature-images {
    img {
      width: 100%;
    }
  }

  .color-pallet {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4rem;

    .color-drop {
      width: 155px;
      height: 140px;
      display: grid;
      place-content: center;
      font-weight: 600;
      border-radius: 61% 39% 46% 54% / 50% 51% 49% 50%;
      box-shadow: 0 2px 10px rgba(black, 0.1);
      text-transform: uppercase;
    }
  }
}

#my-story {
  .main-container {
    display: flex;
    justify-content: space-between;
    .content {
      width: 50%;
      p {
        font-size: 1.5rem;
        line-height: 1.25;
      }
      .icon-flex {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;
        align-items: flex-end;
        .icon {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          align-items: center;
          &:hover > span {
            opacity: 1;
          }
          span {
            opacity: 0;
            font-size: 0.75rem;
            transition: all ease-in-out 200ms;
          }
        }
      }
    }
    .hero-image {
      margin-top: -4rem;
    }
  }
}

.steps {
  display: flex;
  justify-content: center;
  gap: 3rem;
  & > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 0.5rem;
    position: relative;

    &:not(:last-of-type) {
      &::after {
        content: "";
        position: absolute;
        width: 80px;
        height: 2px;
        background: $text-secondary;
        top: 27%;
        left: 30px;
      }
    }

    & > div {
      background: $text-secondary;
      color: $white;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: grid;
      place-content: center;
      position: relative;
    }
    & > span {
      text-align: center;
      font-size: 12px;
    }
    &.active {
      &::after {
        background: $primary;
      }
      div {
        background: $primary;
      }
      span {
        color: $primary;
      }
    }
  }
}

#contact {
  .email {
    text-align: center;
    margin: 5rem auto;
    a {
      color: $text-default;
      font-size: clamp(2rem, 6vw, 10rem);
      font-weight: bold;
      padding: 1.2rem 2rem;
      text-decoration: none;
      position: relative;
      &:hover {
        text-decoration: underline;
      }
      &:hover:after {
        height: 100%;
      }

      &::after {
        content: "";
        height: 0%;
        width: 100%;
        background: $secondary;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: -1;
        transition: all ease 300ms;
      }
    }
  }
}

footer {
  text-align: center;
  padding: 2rem;
  font-size: 0.75rem;
}

.placeholder {
  background: #f1f1f1 !important;
  overflow: hidden;
  position: relative;
  direction: ltr;
  margin: unset !important;
  &::before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    background: linear-gradient(
        120deg,
        rgba(255, 255, 255, 0) 46%,
        rgba(255, 255, 255, 0.35) 50%,
        rgba(255, 255, 255, 0) 54%
      )
      50% 50% !important;
    animation: phAnimation 0.8s linear infinite;

    pointer-events: none;
  }
}

.ph {
  &.feature-portfolio {
    .h3 {
      height: 30px;
      border-radius: 20px;
      margin-bottom: 10px !important;
    }
    .p {
      border-radius: 20px;
      margin-top: 50px !important;
      height: 16px;
    }
    .btn {
      height: 34px;
      margin-top: 64px !important;
      border-radius: 5px;
    }
    .portfolio-image {
      background: #ddd !important;
    }
  }
}

@-webkit-keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
}

@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
}

.spinner {
  position: relative;
}
.spinner::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  inset: 0;
  margin: auto;
  border-radius: 50%;
  border: 4px solid white;
  border-top-color: #ffeb3b;
  animation: rotate 0.6s infinite;
}

.big-spinner {
  position: absolute;
  width: 40px;
  height: 40px;
  inset: 0;
  margin: auto;
  border-radius: 50%;
  border: 4px solid white;
  border-top-color: #ffeb3b;
  animation: rotate 0.6s infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
