// Display

.d-flex {
  display: flex;
  &.justify-space-between {
    justify-content: space-between;
  }
}

//Buttons

.btn {
  border: none;
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem 1.75rem;
  border-radius: 0.25rem;
  transition: all ease-in 400ms;
  &.primary {
    background: $primary;
    color: white;
    &:hover {
      background: darken($primary, 10%);
    }
  }
  &.secondary {
    background: $secondary;
    color: $text-default;
    &:hover {
      background: lighten($secondary, 20%);
    }
  }
  &.transparent {
    font-family: "SK Modernist";
    background: transparent;
    color: $text-secondary;
    &:hover,
    &.active {
      color: $primary;
    }
  }
  &.large {
    font-size: 1.5rem;
    padding: 1rem 2.75rem;
    display: grid;
    place-content: center;
  }

  &:hover {
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.4;
  }
}

.main-heading {
  text-align: center;
  &::after {
    content: "";
    background-image: url("../images/zigzag-big.svg");
    width: 95px;
    height: 16px;
    background-size: cover;
    display: block;
    margin: 2rem auto;
  }
}

.page {
  // padding-top: 12rem;
}

img {
  max-width: 100%;
}
