@keyframes smooth {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 34px;
  }
}

@keyframes scroll {
  0% {
    transform: translateY(-30px);
  }
  0% {
    transform: translateY(20px);
  }
}
