/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 28, 2020 */

@font-face {
    font-family: "SK Modernist";
    src: url("../fonts/sk-modernist-bold-webfont.woff2") format("woff2"),
        url("../fonts/sk-modernist-bold-webfont.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "SK Modernist";
    src: url("../fonts/sk-modernist-regular-webfont.woff2") format("woff2"),
        url("../fonts/sk-modernist-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "sk-modernistmono";
    src: url("../fonts/sk-modernist-mono-webfont.woff2") format("woff2"),
        url("../fonts/sk-modernist-mono-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

$default-font: "SK Modernist";
$color: blue;
